<!-- 
	This is the maintenance page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div> 
		<a-row v-if="buildingInfo.maintenance_disclaimer" >
			<!-- disclaimer with hours of operation and emergency contact -->
			<a-col :span="24" >
				<a-card class="mb-24">
					<div v-html="buildingInfo.maintenance_disclaimer"></div>
				</a-card>
			</a-col>
		</a-row>
		<a-row  :gutter="24" type="flex">
			<a-col  :span="24" class="mb-24">
				<CardMaintenanceActions
					:data="table1Data"
					:columns="table1Columns">
				</CardMaintenanceActions>

			</a-col>

		</a-row>

		

		<!-- Maintenance Table -->
		<a-row :gutter="24" type="flex">

			<!-- Maintenance Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Maintenance Table Card -->
				<CardMaintenanceTable
					:data="table1Data"
					:columns="table1Columns"
					:permissionsInfo="permissionsInfo">
				></CardMaintenanceTable>
				<!-- / Maintenance Table Card -->

			</a-col>
			<!-- / Maintenance Table Column -->

		</a-row>
		<!-- / Maintenance Table -->

	</div>
</template>

<script>

	// "Maintenance" table component.
	import CardMaintenanceTable from '../components/Cards/CardMaintenanceTable.vue';
	import CardMaintenanceActions from '../components/Cards/CardMaintenanceActions.vue';
	import { mapActions } from 'vuex'
	import { mapState } from 'vuex'
	
	// "Maintenance" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'Date Created',
			dataIndex: 'date',
			class: 'text-muted',
			sorter: (a, b) => new Date(a.date) - new Date(b.date), // assuming the 'date' field contains a Date object
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Reported By',
			dataIndex: 'owner',
			scopedSlots: { customRender: 'owner' },
			sorter: (a, b) => a.owner.apartment.localeCompare(b.owner.apartment), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Issue',
			dataIndex: 'issue_w_key',
			scopedSlots: { customRender: 'issue_w_key' },
			sorter: (a, b) => a.issue_w_key.issue.localeCompare(b.issue_w_key.issue), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Status',
			scopedSlots: { customRender: 'status' },
			sorter: (a, b) => a.status.localeCompare(b.status), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Details',
			dataIndex: 'details',
			class: 'font-semibold text-muted text-sm details',
			sorter: (a, b) => a.details.localeCompare(b.details), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Comments',
			dataIndex: 'num_comments_w_key',
			scopedSlots: { customRender: 'num_comments_w_key' },
			sorter: (a, b) => a.num_comments_w_key.num_comments.localeCompare(b.num_comments_w_key.num_comments), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		
		
		{
			title: '',
			scopedSlots: { customRender: 'actionsBtn' },
		},
	];

	

	export default ({
		components: {
    CardMaintenanceTable,
	CardMaintenanceActions
},
		data() {
			return {

				// Associating "Maintenance" table columns with its corresponding property.
				table1Columns: table1Columns,

				
			}
		},
		computed: {
			...mapState({
				table1Data: state => state.maintenance.maintenance,
				buildingInfo: state => state.building.buildingInfo,
				permissionsInfo: state => state.maintenance.permissionsInfo, 
			})
		},
		methods: {
			...mapActions({
				getMaintenance: 'maintenance/getMaintenance',
				getBuildingInformation: 'building/getBuildingInformation',
				initAllowedActions: 'maintenance/initAllowedActions',
				
			}),
		},
		async mounted() {
			window.analytics.page('Maintenance');
			await this.initAllowedActions();
			await this.getMaintenance();
			await this.getBuildingInformation(); 
		},
	})

</script>

<style lang="scss">
.details {
	cursor: pointer;
	white-space: pre-wrap;
}
</style>